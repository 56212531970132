#import {Controller} from "stimulus"
#import 'rater-js'
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import StarRating from 'vue-star-rating'
import VueRateIt from 'vue-rate-it';
import FaRating from 'vue-rate-it'
import Circle from 'vue-rate-it/glyphs/circle';

Vue.use TurbolinksAdapter
document.addEventListener 'turbolinks:load', ->
  elements = document.getElementsByClassName  'ratings'
  return false unless elements.length

  for el in elements
    new Vue {
      el
      components: {
        StarRating
        FaRating
        'fa-rating': VueRateIt.FaRating
      }
      created: ->
        @circle = Circle

    }
